import { createRef } from "react"
import { Vector3 } from "three"

const state = {
  sections: 9,
  pages: 8,
  zoom: 75,
  paragraphs: [
    {
      offset: 1,
      factor: 1.75,
      header: 'denver.thought.center',
      image: "/denver.png",
      aspect: 1.51,
      text: "An open-data fueled exploration into the city of Denver, Colorado. <br/><a target=\"_blank\" rel=\"noopener noreferrer\" href=\"https://denver.thought.center\">denver.thought.center</a>"
    },
    {
      offset: 2,
      factor: 1.75,
      header: 'bachelorspeak.com',
      image: "/bachelorspeak.png",
      aspect: 1.51,
      text: "A unique linguistic analysis of a popular dating show, laying track for a massive data-fueled exploration of semantic similarity in television.<br/><a target=\"_blank\" rel=\"noopener noreferrer\" href=\"https://bachelorspeak.com\">bachelorspeak.com</a>"
    },
    {
      offset: 3,
      factor: 1.75,
      header: 'cargoreturns.com',
      image: "/cargo.png",
      aspect: 1.51,
      text: "Returns, modernized. Cargo introduces a new level of convenience to commerce by allowing people to make returns from home. <br/><a target=\"_blank\" rel=\"noopener noreferrer\" href=\"https://cargoreturns.com\">cargoreturns.com</a>"
    },
    {
      offset: 4,
      factor: 2.0,
      header: "toppgunnroyale.surge.sh",
      image: "/toppgunnroyale.png",
      aspect: 1.5,
      text:
        "An experiment with sandboxed javascript. Write code to blast asteroids and survive in space. <br/><a target=\"_blank\" rel=\"noopener noreferrer\" href=\"https://toppgunnroyale.surge.sh\">toppgunnroyale.surge.sh</a>"
    },
    {
      offset: 5,
      factor: 2.25,
      header: "gpt2 howling",
      image: "/wolves.png",
      aspect: 1.6,
      text:
        "Infinite poetry generation, installed at Wolf People, an <a target=\"_blank\" rel=\"noopener noreferrer\" href=\"http://www.amareanline.com/\">amareanline</a> exhibition in support of WOLF Sanctuary."
    },
    {
      offset: 6,
      factor: 2.0,
      header: "chophaus",
      image: "/chophaus.jpeg",
      aspect: 1.565,
      text:
        "A project with <a target=\"_blank\" rel=\"noopener noreferrer\" href=\"https://audius.co/shebang\">Connor Davis</a>. <br/><a target=\"_blank\" rel=\"noopener noreferrer\" href=\"https://soundcloud.com/beatsbychophaus\">soundcloud.com/beatsbychophaus</a>"
    },
    {
      offset: 7,
      factor: 1.75,
      header: "kid cassette",
      image: "/kidcassette.jpeg",
      aspect: 1.55,
      text:
      "LOFI playground. <br/><a target=\"_blank\" rel=\"noopener noreferrer\" href=\"https://audius.co/kidcassette\">audius.co/kidcassette</a>"
    },
    // { offset: 7, factor: 1.05, header: "The Factory", image: "/photo-1548191265-cc70d3d45ba1.jpeg", aspect: 1.77, text: "Education and enlightenment." }
  ],
  stripes: [
    { offset: 0, color: "#000", height: 13 },
    { offset: 1.2, color: "#FFFFE9", height: 21 },
    // { offset: 2.4, color: "#3454D1", height: 20 },
    { offset: 4.9, color: "#FFFFE9", height: 10 },
    { offset: 6.5, color: "#FFFFE9", height: 12 },
    { offset: 8.1, color: "#2ddab8", height: .1},
    { offset: 8.15, color: "#D40749", height: .1},
    { offset: 8.2, color: "#FFFFE9", height: .1},
  ],
  diamonds: [
    { x: 0, offset: 0.15, pos: new Vector3(), scale: 0.6, factor: 1.8 },
    { x: 2, offset: 1.1, pos: new Vector3(), scale: 0.8, factor: 2.1 },
    { x: -5, offset: 2, pos: new Vector3(), scale: 0.8, factor: 2.5 },
    { x: 0, offset: 3.2, pos: new Vector3(), scale: 1.15, factor: 0.95 },
    { x: -4, offset: 4, pos: new Vector3(), scale: 0.8, factor: 2.5 },
    { x: 2, offset: 5.1, pos: new Vector3(), scale: 0.8, factor: 2.1 },
    { x: -5, offset: 6, pos: new Vector3(), scale: 0.8, factor: 2 },
    { x: 1, offset: 7.1, pos: new Vector3(), scale: 0.4, factor: 1.7 },
    { x: 0, offset: 8, pos: new Vector3(), scale: 1.5, factor: 6 }
  ],
  top: createRef()
}

export default state
